@use 'swiper/scss';

:root {
  --fashion-slider-button-side-offset: 32px;
  --fashion-slider-button-mobile-side-offset: 24px;
  --fashion-slider-button-mobile-bottom-offset: 16px;
}

// Navigation Buttons
.fashion-slider-button {
  transition: 0.5s;
  outline: none;
  position: absolute;
  width: 140px;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  svg {
    display: block;
    transition: 0.5s;
  }

  .fashion-slider-svg-circle-wrap {
    transition: 0.5s;
    transform-origin: -20px 40px;
    opacity: 1;
  }

  circle {
    transition: 0.5s;
    stroke-width: 2px;
    stroke: #fff;
    fill: #00000055;
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
    opacity: 1;
    transform-origin: 0px 0px 0px;
  }

  .fashion-slider-svg-arrow {
    transition: 0.5s;
    fill: #fff;
    transform: rotateY(180deg) translate(-55px, 36.1px) scale(1.75);
  }

  &-prev {
    right: var(--fashion-slider-button-side-offset);
  }

  &-next {
    left: var(--fashion-slider-button-side-offset);
  }

  @media (max-width: 640px) {
    top: auto;
    bottom: var(--fashion-slider-button-mobile-bottom-offset);
    transform: none;

    &-prev {
      right: var(--fashion-slider-button-mobile-side-offset);
    }

    &-next {
      left: var(--fashion-slider-button-mobile-side-offset);
    }
  }

  &-disabled {
    opacity: 0.2;
    cursor: default;
  }

  .fashion-slider-svg-wrap {
    transform: translateY(353px);
  }

  &-prev .fashion-slider-svg-wrap {
    transform: translateY(353px) rotateY(180deg);
    transform-origin: 80px 0px 0px;
  }

  .fashion-slider-svg-line {
    transition: 0.5s;
    stroke: #fff;
    stroke-width: 2;
    transform: translate(50px, 42px);
  }

  &-prev:not(&-disabled):hover svg {
    transform: translateX(-16px);
  }

  &-next:not(&-disabled):hover svg {
    transform: translateX(16px);
  }

  &:not(&-disabled):hover {
    .fashion-slider-svg-circle-wrap {
      transform: scale(1.1);
    }

    circle {
      stroke-dasharray: 4px;
      stroke-dashoffset: 4px;
      opacity: 1;
    }

    .fashion-slider-svg-arrow {
      transform: rotateY(180deg) translate(-40px, 36.1px) scale(1.75);
    }

    .fashion-slider-svg-line {
      transform: translate(35px, 42px) scaleX(0.5);
    }
  }
}
